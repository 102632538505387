export const DEFAULT_CONSTANTS = {
  BANNER_IMAGE_CATEGORIES:
    'https://sharetribe-assets.imgix.net/65860685-5fc1-4a52-939a-9cb38eda2e94/raw/8f/f539ebe9571878b2c5ffaccf05c8488345d860?auto=format&fit=clip&h=2400&w=2400&s=6a62d48abde9baa0ce6c4d11bba31f8b',
  CURRENCY_SYMBOL: '$',
};

export const PRICE_CODES = {
  DAY: 'day',
  PER_WEEK: 'perWeek',
  PER_MONTH: 'perMonth',
};
