import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(true);
        observer.unobserve(ref.current);
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const sortArrayByLabel = arr => {
  const defaultLocale = getDefaultLocale();

  const collator = new Intl.Collator(defaultLocale, { sensitivity: 'base' });

  return (
    isArrayLength(arr) &&
    arr.sort((a, b) => {
      return collator.compare(a.label, b.label);
    })
  );
};

/**
 * Display a toast notification based on the message type.
 *
 * @param {string} type The type of toast to display ('success' or 'error').
 * @param {string} message The message to display in the toast.
 * @param {Object} [options] Additional options for customizing the toast appearance and behavior.
 */
export const showToaster = (type, message, options = {}) => {
  const defaultOptions = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    ...options, // Merge any additional options passed to the function
  };

  // Display the toast based on the type
  if (type === 'success') {
    toast.success(message, defaultOptions);
  } else if (type === 'error') {
    toast.error(message, defaultOptions);
  } else {
    console.warn('showToast called with invalid type. Expected "success" or "error".');
  }
};

export const isDevMode = () => {
  return process.env.REACT_APP_MARKETPLACE_ROOT_URL === 'http://localhost:3000';
};

export const keepValidGermanChars = inputString => {
  // This regex matches all characters that aren't part of the German alphabet, numerics or spaces
  const regex = /[^a-zA-Z0-9äöüß ]/g;

  // Remove text enclosed within square brackets followed by a URL enclosed within parentheses
  const updatedString = inputString.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

  // Replace all matches with an empty string
  return updatedString.replace(regex, ' ');
};

/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return (str.charAt(0).toUpperCase() + str.slice(1)).trim();
};

// Helper export  to check if exactly one of the values is truthy and value1 is a non-empty array
export function isOneTruthyAndValidArray(value1, value2) {
  // Check if value1 is a non-empty array and value2 is falsy
  const isValue1ValidArray = Array.isArray(value1) && value1.length > 0;
  // Check if exactly one of the values is truthy
  return (isValue1ValidArray && !value2) || (!isValue1ValidArray && value2);
}

export const extractSeoCategoryData = data => {
  const title = data?.attributes?.title;
  const bannerTitle = data?.attributes?.bannerTitle;
  const description = data?.attributes?.mainDescription;
  const content = data?.attributes?.content;
  const heading = data?.attributes?.heading;
  const url = data?.attributes?.url;

  const headingImage = `${process.env.REACT_APP_STRAPI_BASE_URL}${data?.attributes?.headingImage?.data?.attributes?.url}`;
  const bannerImage = `${process.env.REACT_APP_STRAPI_BASE_URL}${data?.attributes?.bannerImage?.data?.attributes?.url}`;

  return {
    title,
    description,
    content,
    heading,
    headingImage,
    url,
    bannerTitle,
    bannerImage,
  };
};

export const getSuffixFromPricing = pricing => {
  if (!Array.isArray(pricing) || pricing.length === 0) {
    return null;
  }
  return pricing[0].name;
};

export const getValidPrice = pricing => {
  if (!Array.isArray(pricing) || pricing.length === 0) {
    return null;
  }
  return pricing.find(option => option.price !== undefined && option.price !== null);
};

export function extractLineItemsCodes(lineItems) {
  return lineItems.map(item => {
    let codeParts = item.code.split('/');
    return codeParts[1]; // Extract the part after '/'
  });
}

export const getKeysWithBaseUrl = contractFiles => {
  const baseUrl = process.env.REACT_APP_CF_URL;
  return contractFiles.map(url => `${baseUrl}/${url.key}`);
};

export const generateTimeSlots = selectedDate => {
  const currentDate = moment();
  const selectedMoment = moment(selectedDate);
  let timeSlots = [];

  if (selectedMoment.isSame(currentDate, 'day')) {
    // Selected date is today, filter out past time slots
    const currentTime = currentDate.startOf('minute');
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const slotTime = moment({ hour, minute });
        if (slotTime.isSameOrAfter(currentTime)) {
          timeSlots.push(slotTime.format('h:mm A'));
        }
      }
    }
  } else {
    // Selected date is any other day, return all possible time slots
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const slotTime = moment({ hour, minute });
        timeSlots.push(slotTime.format('h:mm A'));
      }
    }
  }

  return timeSlots;
};


export const getExtendDays = (transaction, endDate) => {
  if (!transaction?.booking?.attributes?.end || !endDate) {
    return 0;
  };
  const bookingEndDate = new Date(transaction.booking.attributes.end);
  const providedEndDate = new Date(endDate);
  const differenceInTime = providedEndDate.getTime() - bookingEndDate.getTime();
  if (differenceInTime < 0) {
    return 0; 
  };

  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;

};


export const getTitleFromError = (error) => {
  if (error && error.apiErrors && Array.isArray(error.apiErrors) && error.apiErrors.length > 0) {
    const firstError = error.apiErrors[0]; 
    return firstError.title;
  }
  return null;
};